<template>
  <div class="designlab">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <LabContent :notiondata="data" lab="designlab" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
      <Contactform
        lab="designlab"
        contactheadline="zum DesignLab"
        team="Prof. Angela Clemens"
        welcomemsg="Das Team vom DesignLab und ich unterstützen gerne beim Realisieren von Ideen!"
      />
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import LabContent from "@/components/notion/LabContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";
import Contactform from "@/components/Contactform.vue";

export default {
  name: "DesignLab",
  components: {
    Meta,
    LabContent,
    BlockContent,
    Contactform,
  },
  data() {
    return {
      NotionPageID: "c4fbc0f1ec65402e8fdc126529a66a9c",
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
  },
};
</script>

<style lang="scss"></style>
